import axios from '@/jslib/axios'

export const verifyLogin = data => {
  return axios({
    method: 'post',
    url: '/api/appointee/verify',
    data
  })
}
export const appointeeDownload = params => {
  return axios({
    method: 'get',
    url: '/api/appointee/file',
    params
  })
}
